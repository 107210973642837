<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%;border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">渠道数据(单位:比索 MXN)</p>
            <table style="width: 99.5%; margin-top: -10px; line-height: 38px;">
                <el-row :gutter="20" style="margin-left: 5px;">
                    <el-col :span="6">
                        <el-card shadow="always">
                            <div>汇总</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        总服务费
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{tl.value1}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        总笔数
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{tl.value2}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card shadow="always" style="background-color: #ebf4ff;">
                            <div>代收</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        总服务费
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{tl.value3}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        总笔数
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{tl.value4}}</div>
                                    </div>
                                </el-col>
                            
                            </el-row>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card shadow="always" style="background-color: #ebf4ff;">
                            <div>代付</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        总服务费
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{tl.value5}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        总笔数
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{tl.value6}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                </el-row>
            </table>
        </div>

        <div class="top" style="margin-left: 32px;">
            <span>
                <el-date-picker
                    v-model="time"
                    size="small"
                    value-format="yyyy-MM-dd"
                    @change="checkdata"
                    type="daterange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </span>
            <span style="margin-left:28px;">
                <el-select filterable v-model="mchId" placeholder="请选择商户" @change="checkdata">
                    <el-option v-for="item in mchlist" :key="item.id" :label="item.nickName" :value="item.id">
                    </el-option>
                </el-select>
            </span>
        </div>
        <div style="width: 96%; margin-left:2%; border: 1px solid #ddd;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 5px;">数据统计</p>
            <div>
                <el-table :data="list"
                    :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                    <el-table-column prop="time" label="日期"></el-table-column>
                    <el-table-column prop="collectionTotalNum" label="代收笔数"></el-table-column>
                    <el-table-column prop="dailyServiceCharge" label="代收服务费"></el-table-column>
                    <el-table-column prop="payTotalNum" label="代付笔数"></el-table-column>
                    <el-table-column prop="dailyServicePay" label="代付服务费"></el-table-column>
                </el-table>
                <div class="page">
                    <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                        @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                </div>
            </div>
        </div>

        <div style="width: 96%; margin-left:2%; border: 1px solid #ddd;margin-top:18px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 5px;">数据流水
                <el-button style="margin-left:10px;" size="small" type="primary" @click="download">导出</el-button>
            </p>
            <div>
                <el-table :data="list1"
                    :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                    <el-table-column prop="time" label="日期"></el-table-column>
                    <el-table-column prop="collectionTotalMoney" label="代收金额"></el-table-column>
                    <el-table-column prop="dailyServiceCharge" label="代收服务费"></el-table-column>
                    <el-table-column prop="payTotalMoney" label="代付金额"></el-table-column>
                    <el-table-column prop="dailyServicePay" label="代付服务费"></el-table-column>
                    <el-table-column prop="nation" label="国家"></el-table-column>
                </el-table>
                <div class="page">
                    <app-page :total="total1" :current="pageNo1" :pageSize="pageSize1"
                        @handleCurrentChange="currentchange1" @handleSizeChange="handchange1"></app-page>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import fileDownload from "js-file-download";
import apiurl from "@/api/public";
import _api from "@/api/index";
export default {
    data(){
        return{
            tl:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
                value5: 0,
                value6: 0,
            },
            total: 1,
            pageNo: 1,
            pageSize: 10,
            time:null,
            list:[],

            total1: 1,
            pageNo1: 1,
            pageSize1: 10,
            list1:[],
            mchId:null,
            mchlist:[],
        }
    },
    created(){
        this.user=JSON.parse(localStorage.getItem("userinfo"))
        this.time = this.getNowTime()
        this.getList()
        this.getList1()
        this.getmchlist()
        this.getListDay()
    },
    methods:{
        checkdata(){
            this.getListDay()
            this.getList1()
        },
        getNowTime() {
            const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 7)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0] //默认开始时间7天前
            const end = new Date(new Date().getTime() - 3600 * 1000 * 24)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0]//默认结束时间1天前
            return [start, end]
        },
        download() {
            _api.download(apiurl.download2, {
                time: this.time,
                mchId:this.mchId,
            }).then(res => {
                let a = document.createElement('a');
                let blob = new Blob([res], {type: "application/vnd.ms-excel"});
                let objectUrl = URL.createObjectURL(blob);
                a.setAttribute("href", objectUrl);
                a.setAttribute("download", '商户流水数据.xls');
                a.click();
            })
        },
        getList() {
            _api.get(apiurl.total, {
                userId: this.user.user.id
            }).then(res => {
                this.tl.value1=res.data.value1,
                this.tl.value2=res.data.value2,
                this.tl.value3=res.data.value3,
                this.tl.value4=res.data.value4,
                this.tl.value5=res.data.value5,
                this.tl.value6=res.data.value6,
                this.mchId = res.data.value
            })
        },
        getListDay() {
            _api.get(apiurl.totalDay, {
                page: this.pageNo,
                size: this.pageSize,
                time: this.time,
                mchId:this.mchId,
            }).then(res => {
                this.list = res.data.data
                this.total = res.data.totalCount
            })
        },
        getList1() {
            _api.get(apiurl.totalDay1, {
                page: this.pageNo1,
                size: this.pageSize1,
                time: this.time,
                mchId:this.mchId,
            }).then(res => {
                this.list1 = res.data.data
                this.total1 = res.data.totalCount
            })
        },
        getmchlist(){
            _api.get(apiurl.users, {
                roleId: 2,
                disId:this.user.user.id
            }).then(res => {
                this.mchlist = res.content;
            })
        },
        handchange(data) { //分页Size变化
            this.pageSize = data
            this.pageNo = 1
            this.getListDay()
        },
        currentchange(data) { //当前页变化
            this.pageNo = data
            this.getListDay()
        },

        handchange1(data) { //分页Size变化
            this.pageSize1 = data
            this.pageNo1 = 1
            this.getList1()
        },
        currentchange1(data) { //当前页变化
            this.pageNo1 = data
            this.getList1()
        },
    }
}
</script>
<style scoped>
    .ta {
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 800px;
        text-align: left;
        width: 750px;
    }
    .div1 {
        width: 150px;
        height: 50px;
        line-height: 50px;
        background-color: aqua;
        border: 1px solid  black;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
        margin-left: 100px;
    }

.toptj{ display: flex;margin-top: -8px;}
.tjitem{ flex: 1; background: #ffffff; cursor: pointer; border: #ebedf0 solid 1px; height: 100px; margin-right:10px;padding: 8px 12px;}
.tjitem:last-child{margin-right:0; }
.tjitem span{ display: block; color: #999999; padding-top: 4px;}
.tjitem div{ height: 25px; line-height: 25px; margin-top: 10px;}
.tjitem div i{ font-size: 24px; font-weight: bold; color: #333;}
.tjitem div em{ float: right; color: #999999;}
.contbox{ overflow: hidden; display: flex;}
.contboxleft{ flex: 0 0 50%;}
.contboxright{flex: 0 0 50%;}
.tjcont{ padding-left: 15px;}
.title em{ vertical-align: middle; display: inline-block; width: 3px; height:15px; background: #FF7300;}
.title b{ vertical-align: middle; margin-left:8px; font-size: 16px;}
.title a{ float: right; margin-right:20px; font-size: 12px; color: #56a3f3;}
.phcont{ padding:8px 0; padding-right: 25px;}
.phspan{ display: inline-block; color: #ffffff; font-size: 12px; padding:0 7px;  line-height: 20px; height: 20px; text-align: center; border-radius: 10px;}
.spanother{ background: #b9b9b9;}
.span1{ background:#CD012C;}
.span2{ background:#BE4C05;}
.span3{ background:#093666;}
.buttonon{background:rgb(230, 235, 240)!important;color:rgb(9, 54, 102)!important;border:rgb(181, 195, 209) solid 1px!important;}
</style>